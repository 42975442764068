.oneColImgContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.oneColImg {
    width: 40%;
}


@media screen and (max-width: 700px) {
    .oneColImg {
        width: 100%;
    }
}