.navStyle {
    font-family: 'Fjalla One', sans-serif;
    padding-top: 30px;
  }
  
  .navbar-light .navbar-nav .nav-link {
    color: #282c34;
  }
  
  .dropdown-toggle.nav-link::after {
    content: none;
  }
  
  .smallBox{
    background: linear-gradient( #ffffff 50%, #f1dfd4 50% );
    padding-left: 5px;
    padding-right: 5px;
  }

  .smallBoxBackground {
    background: linear-gradient( #f1dfd4 50%, #ffffff 50%);
    padding-left: 5px;
    padding-right: 5px;
  }
  
  .hoverBox:hover{
    background: linear-gradient( #ffffff 50%, #f1dfd4 50% );
    padding-left: 5px;
    padding-right: 5px;
  }

  .hoverBoxBackground:hover{
    background: linear-gradient( #f1dfd4 50%, #ffffff 50%);
    padding-left: 5px;
    padding-right: 5px;
  }
  
  .hoverBoxDrop:hover{
    background: linear-gradient( #ffffff 50%, #f1dfd4 50% );
  }
  
  .hoverBoxDropBackground:hover{
    background: linear-gradient( #f1dfd4 50%, #ffffff 50%);
  }
  
  .dropAxis{
    z-index: 1001;
  }
  
  .divider{
    width: 5%;
    padding-left: 75px;
  }
  
  .dropItem{
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 1px;
  }
  
  .dropdown-menu{
    border-radius: 0px;
    border: none;
    min-width: 7rem;
    z-index: -1;
    padding-top: 30px;
    top: 15%;
    left: -10px;
  }

  .navDiv{
    letter-spacing: 2px;
  }

  .dropdown-item.active, .dropdown-item:active
  {
    background-color: #f1dfd4;
    color:black;
  }