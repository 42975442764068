.BoldSectionTextContainer{
    justify-content: start;
    align-items: flex-start;
    margin-bottom: 1vw;
}

.BoldSectionText{
    font-size: 1.35vw;
    font-weight: 700;
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
}

@media screen and (min-width: 1600px) {
    .BoldSectionText {
        font-size: 17px;
    }
}

@media screen and (min-width: 1400px) {
    .BoldSectionText {
        font-size: 17px;
    }
}

@media screen and (max-width: 1200px) {
    .BoldSectionText {
        font-size: 1.50vw;
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .BoldSectionText {
        font-size: 2vw;
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .BoldSectionText {
        font-size: 3vw;
        width: 100%;
    }
}

