

@media (min-width: 1140px) {
    .FooterBckgrd {
        margin-left: calc(-100vw / 2 + 932px / 2);
        margin-right: calc(-100vw / 2 + 932px / 2);
     
    }
}

@media (max-width: 1140px) {
    .FooterBckgrd {
        margin-left: calc(-100vw / 2 + 930px / 2);
        margin-right: calc(-100vw / 2 + 930px / 2);
     
    }
}

@media (max-width: 992px) {
    .FooterBckgrd {
        margin-left: calc(-100vw / 2 + 690px / 2);
        margin-right: calc(-100vw / 2 + 690px / 2);
     
    }
}

@media (max-width: 768px) {
    .FooterBckgrd {
        margin-left: calc(-100vw / 2 + 510px / 2);
        margin-right: calc(-100vw / 2 + 510px / 2);
      
    }
}

@media (max-width: 576px) {
    .App .FooterBckgrd {
        margin-left: calc(-100vw / 2 + 290px / 2);
        margin-right: calc(-100vw / 2 + 290px / 2);
        flex-direction: column;
        height: 320px;
    }

    .FooterLinksWrapper{
        justify-content: center;
    }

    .FooterTitle{
        margin-top: 30px;
    }
}

.FooterTitle{
    color:black;
    font-family: 'Fjalla One', sans-serif;
    letter-spacing: .15vw;
}

.FooterBckgrd{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: #f1dfd4;
    height: 240px;
    padding-top: 25px;
    margin-top: 90px;
}

.FooterLink{
    color:black;
    list-style-type: none;
    text-align: left;
    margin-left:-12px;
    line-height: 30px;
}

.FooterLinksWrapper{
    flex-direction: row;
    display: flex;
}

.FooterAboutWrapper{
    margin-left: 80px;
}

.FooterLinkText {
    color: black;
}

.FooterLinkText:hover {
    color: black;
}

.LinkTitle {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    line-height: 1.2;
    margin-top: 0;
}

.LinkTitle:hover {
    color: black;
}