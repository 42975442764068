.usabilityBtn {
    background-color: black;
    color: white;
    padding-left: 20px;
    height: 50px;
    width: 200px;
    padding-top: 10px;
}

.usabilityBtn:hover {
    cursor: pointer;
}