
.title{
    text-align: center;
    padding-bottom: 25px;
    font-family: 'Fjalla One', sans-serif;
    font-size: 22px;
    letter-spacing: 1.5px;
}

.imgBorder{
    border-style: solid;
    border-color: #e6e6e6;
}

img {
  max-width: 100%;
}

.imgHover:hover {
    cursor: pointer;
}

.spacingBtm {
    margin-bottom: 30px;
}

.text {
    background-color: none;
    color: #323132;
    font-size: 12px;
    padding: 16px 32px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
}

.text:hover {
    cursor: pointer;
}

.container:hover > .image {
    opacity: 0.1;
  }
  
  .container:hover > .middleInfo {
    opacity: 1;
  }

  .middleInfo {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  .image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
  }

  .dropdownTitle + .dropdown-menu.show{
    z-index: 1;
    width: 180px;
    padding-top: 0px;
  }

  .dropdownTitle{
    color: #282c34;
    background-color: transparent;
    border-color: transparent;
    text-align: center;
    padding-bottom: 25px;
    font-family: 'Fjalla One', sans-serif;
    font-size: 22px;
    letter-spacing: 1.5px;
  }

  .dropdownTitle:hover{
    color: black;
    background-color: transparent;
    border-color: transparent;
  }

  .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle{
    color: black;
    background-color: transparent;
    border-color: transparent;
  }

  .btn-primary.focus, .btn-primary:focus{
    box-shadow: none;
  }

  .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus
  {
    box-shadow: none;
  }
