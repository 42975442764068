.uxContainer{
    width: 540px;
    padding-bottom: 50px;
}

.uxMainContainer {
    display: flex;
    flex-Direction: row;
    flex-Wrap: wrap;
}

@media screen and (max-width: 1200px) {

    .uxMainContainer{
        justify-content: center;
    }
    
}