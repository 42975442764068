.LinkBtn{ 
    background-color:rgb(241, 223, 212);
    color:black;
    font-weight: bold;
    padding: 15px 25px 15px 25px;
    border-radius: 10px;
   
    
    
       
}

a.LinkBtn:hover {
    background-color: #e5c8bd;
    color:black;
    text-decoration: none;
}

.LinkBtnWrapper{
    display: flex;
    justify-content: left;
}

.ButtonFullPadding{
    padding: 25px 0px;
}

.GifWrapper{
    border: #c7c7c7 solid .5px;
}