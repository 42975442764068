.footer {
    background-color: black;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    color: white;
    height: 10vw;
}
.footerContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 1vw;
}
.footerText {
    text-align: center;
    font-size: 1.5vw;
}

.imgContainer {
    margin-bottom: 15vw;
}

.prevBtn {
    margin-left: 2vw;
}

.nextBtn {
    margin-right: 2vw;
    align-self: flex-end;
}

.questionDiv {
    width: 85%;
}

.cycleBtn {
    border-radius: 50px;
    font-size: 3vw;
    height: 5vw;
    width: 5vw;
}