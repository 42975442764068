.twoColImgContainer {
    flex-direction: row;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.twoColImg {
    width: 35%;
}

@media screen and (max-width: 500px) {
    .twoColImgContainer {
        flex-direction: column;
        display: flex;
        background-color: aliceblue;
        width: 100%;
    }
    .twoColImg {
        width: 100%;
    }
}
