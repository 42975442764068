.threeColImgContainer {
    flex-direction: row;
    display: flex;
    width: 100%;
}

.threeColImg {
    width: 33%;
    border: none;
    max-width: 100%;
}

@media screen and (max-width: 500px) {
    .threeColImgContainer {
        flex-direction: column;
        display: flex;
        background-color: aliceblue;
        width: 100%;
    }
    .threeColImg {
        width: 100%;
    }
}