.bigLink {
    color: #323132;
    font-size: 100px;
    font-family: 'DM Serif Display', serif;
}

.bigLink:hover {
    color: gray;
    text-decoration: none;
}

.home{
    margin-top: 75px;
    line-height: 1;
}

@media only screen and (max-width: 600px)
{
    .bigLink{
        font-size: 75px;
    }
}

@media only screen and (max-width: 450px)
{
    .bigLink{
        font-size: 50px;
    }
}

@media only screen and (max-width: 300px)
{
    .bigLink{
        font-size: 40px;
    }
}

@media only screen and (max-width: 250px)
{
    .bigLink{
        font-size: 30px;
    }
}

.top{
    margin-top: 100px;
}

@media only screen and (max-width: 775px)
{
    .top{
        margin-top: 20px;
    }
}