.About {
    background-color: white;
    margin-top: 100px;
}

h2 {
    font-family: 'DM Serif Display', serif;
    text-align: left;
}

p {
    font-family: 'Open Sans', sans-serif;
    text-align: left;
}

.aboutImg {
    max-width: 100%;
    margin-top: 10px;
    border: none;
}

.hidden {
    background-color: transparent;
    width: 100px;
    height: 100px;
}

@media only screen and (max-width: 575px)
{
    .aboutText{
        margin-top: 20px;
    }

    .aboutImg{
        max-width: 75%;
    }

    .About{
        margin-top: 20px;
    }
}

.TextLinkBtn{
    color: #575757;
    text-decoration: underline;
    padding-top: 10px;
    font-style: italic;


}

a.TextLinkBtn:hover{
    color: black
}