@media only screen and (max-width: 500px)
{
    .col-6{
        flex: none;
        max-width: none;
    }
}

.middleBrand{
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 61%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.container:hover > .image {
    opacity: 0.1;
  }
  
  .container:hover > .middleBrand {
    opacity: 1;
  }