.subSectionContainer {
    justify-content: start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 1vw;
    margin-top: 1vw;
}

.subSectionText {
    font-size: 1.25vw;
    margin: 0;
    padding: 0;
    font-family: 'Fjalla One', sans-serif;
    text-align: left;
    letter-spacing: .15vw;
}

@media screen and (min-width: 1600px) {
    .subSectionText {
        font-size: 22px;
    }
}

@media screen and (max-width: 1200px) {
    .subSectionText {
        font-size: 1.75vw;
        width: 100%;
        letter-spacing: .2vw;
    }
}

@media screen and (max-width: 800px) {
    .subSectionText {
        font-size: 3vw;
        width: 100%;
        letter-spacing: .2vw;
    }
}

@media screen and (max-width: 500px) {
    .subSectionText {
        font-size: 4vw;
        width: 100%;
        letter-spacing: .3vw;
    }
}