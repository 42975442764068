.sectionHeaderContainer{
    justify-content: start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 1vw;
    margin-top: 1vw;
}

.sectionHeaderText{
    font-family: 'DM Serif Display', serif;
    font-size: 3.25vw;
    margin: 0;
    padding: 0;
}

@media screen and (min-width: 1600px) {
.sectionHeaderText{
        font-size: 40px;
    }
}
    
@media screen and (max-width: 800px) {
.sectionHeaderText{
        font-size: 5vw;
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
.sectionHeaderText{
        font-size: 6vw;
        width: 100%;
    }
}