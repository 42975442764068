.simpleBtn {
    background: none;
    border: none;
    color: #575757;
    padding-bottom: 15px;
}

.simpleBtn:hover {
    color: black;
}

.btnDiv{
    text-align: left;
}

.imgContainer {
    margin-top: 100px;
}